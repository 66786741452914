import React, { useEffect, useState } from 'react';
import './Home.css'
import './About.css'
import Contact from './Contact';
import Nav from './Nav'
import wood_rider_1 from '../Images/wood_rider_1.png'
import wood_home_1 from '../Images/wood_home_1.png'
import wood_trophy_1 from '../Images/wood_trophy_1.png'
import wood_horse_1 from '../Images/wood_horse_1.png'
// import AccordionItem from './Accordion';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

import CoverflowCarousel from './Coverflow';
import './About.css'
import Accordion2 from './Accordion2';

import trainer1 from '../Images/trainers/trainer_1.jpg'
import trainer2 from '../Images/trainers/trainer_2.jpg'
import Qualified from './Qualified';

const Home = () => {

    const [showGif, setShowGif] = useState(false);

  useEffect(() => {
    // Check if the GIF has already been shown in the current session
    const hasVisited = sessionStorage.getItem("hasVisited");

    if (!hasVisited) {
    //   setShowGif(true); // Show GIF for the first time in the session
      sessionStorage.setItem("hasVisited", "true"); // Mark GIF as shown in the session

      // Set a timeout to transition from the GIF to the home content
      setTimeout(() => {
        setShowGif(true);
      }, 3000); // Adjust duration for your GIF

    //   return () => clearTimeout(); // Cleanup the timer on component unmount
    }
    else{
        setShowGif(true)
    }
  }, []);

    

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 200,
    //     slidesToShow: 5,
    //     slidesToScroll: 2,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     pauseOnHover: true,
    //     responsive: [
    //         {
    //             breakpoint: 2300, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 5, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 2050, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 4, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 1350, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 3, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 850, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 2, // Show 3 cards at a time
    //                 slidesToScroll: 2, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 600, // Adjust for large screens
    //             settings: {
    //                 slidesToShow: 1, // Show 3 cards at a time
    //                 slidesToScroll: 1, // Scroll 2 cards per transition
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //     ]
    //     }


//     const testimonial_data= [{
//         id:1,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
//     {
//         id:2,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
//     {
//         id:3,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
//     {
//         id:4,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
//     {
//         id:5,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
//     {
//         id:6,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
//     {
//         id:7,
//         txt:'Awesome! I love how easy it was to connect with the horse with the expert help of my Happy Rider instructor!',
//         name:'Lisa Brown',
//         loc:'EI PASO, TX'
//     },
// ]




useEffect(()=>{
    document.title = 'Ruta - Home'
},[])

    

    
    return (
        <div>

    
            <div className="">
                <Nav/>
            </div>
            {showGif ? <div className="">

            <section id="home" >
            <div class="home_main">
                <div class="home_head ">
                    <h2>Center for Horse Sport</h2>
                    <h1>
                    Ruta Equestrians</h1>
                </div>

                <div class="home_txt">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30.626" height="48.573" viewBox="0 0 30.626 48.573" class="st-current"><g transform="translate(-414.292 -8759.645)"><path d="M1761.13,8807.318h-2v-46.674h2Z" transform="translate(-1330.524 -1)"></path><path d="M1649.148,8805.509l-15.313-15.313,1.414-1.414,13.9,13.9,13.9-13.9,1.414,1.414Z" transform="translate(-1219.543 2.709)"></path></g></svg>
                    <p>Welcome to Ruta Equestrians - Your Premium <br /> Horse Riding School in Aziz nagar, Hyderabad</p>
                </div>
            </div>
      </section>


      <section>
        <div class="">

            <div class="riding_main">
                <div class="riding_txt_left">
                    <p>Joy of Riding!</p>
                    <div class="riding_txt_head">
                        <h1>Full Service </h1>
                        <h1>Ruta Equestrians</h1>
                    </div>
                </div>
                <div class="riding_txt_right">
                    <p>Welcome to Ruta Equestrians, where you can experience the ultimate joy of horse riding. Located in the heart of Aziz nagar, Hyderabad, we offer an excellent riding school for everyone, whether you're a beginner or an experienced rider. 
                    </p>
                    <p>Whether you're here to learn new skills or simply enjoy time with horses, we have something exciting for you!</p>
                </div>
            </div>


            <div class="riding_card">
                <div class="riding_card_sub">
                    <img src={wood_rider_1} alt=""/>
                    <h2>Professional <br/> Coaches</h2>
                    <p>
                        {/* <span class="read_more">Read More</span> */}
                         <i class='bx bx-right-arrow-alt' ></i></p>
                </div>
                <div class="riding_card_sub">
                    <img src={wood_horse_1} alt=""/>
                    <h2>Beginners <br/> Training</h2>
                    <p>
                        {/* <span class="read_more">Read More</span> */}
                         <i class='bx bx-right-arrow-alt' ></i></p>
                </div>
                <div class="riding_card_sub">
                    <img src={wood_trophy_1} alt=""/>
                    <h2>Competitions <br/> Training</h2>
                    <p>
                        {/* <span class="read_more">Read More</span> */}
                         <i class='bx bx-right-arrow-alt' ></i></p>
                </div>
                <div class="riding_card_sub">
                    <img src={wood_home_1} alt=""/>
                    <h2>Beyond <br/> Riding</h2>
                    <p>
                        {/* <span class="read_more">Read More</span> */}
                         <i class='bx bx-right-arrow-alt' ></i></p>
                </div>
            </div>
        </div>
      </section>


      <section class="individual_sec">
        <div class="individual">
            <div class="indi_img_1">
                <img src="https://happy-rider.ancorathemes.com/wp-content/uploads/2022/05/free-about-img-1.jpg" alt=""/>
            </div>
            <div class="indi_img_2">
                <div class="indi_txt">
                    <h6>Why Choose us</h6>
                    <div class="">
                    <h2>Why Choose Ruta Equestrians?</h2>
                    </div>
                    <p>Ruta Equestrians is more than just a riding school; it's a friendly home for riders of any level. Our trainers are experienced, friendly, and willing to help you gain confidence, whether you are a beginner or an advanced rider. We offer various programs, such as dressage and show jumping, as well as fun activities like pony rides and treasure hunts. We also teach skilled horse care, allowing you to connect more with your horse. Ruta Equestrians provides a great opportunity to be part of an encouraging community that makes learning to ride fun,  rewarding and a memorable experience.</p>
                </div>
                <img src="https://happy-rider.ancorathemes.com/wp-content/uploads/2022/05/free-about-img-2.jpg" alt=""/>
            </div>
        </div>
      </section>

      <div className="">
        <Qualified/>
      </div>




<section  id="meet_ins">
    <div class="">

        <div class="abt_card_main meet_card_main">
            <div className="meet_trainers_left" style={{width:'50%'}}>
            <div class="our_shop_head all_head all_head_2">
            <h6>
            MEET OUR TRAINERS
                </h6>
            <h1>Passionate Instructors at Your Service</h1>
            <p className='meet_ins_p'> Our dedicated trainers are here to ensure your riding journey is enjoyable. With their expertise and friendly approach, you'll feel confident and supported as you learn to ride.</p>
        </div>
            <div class="abt_card" style={{width:'100%'}}>
                <div class="">
                <img src={trainer1} alt="" className=''/>
                </div>
                <div class="abt_card_txt abt_card_txt_2">
                    <h5>Shiva Prasad</h5>
                    <p>Horse Trainer</p>
                </div>
            </div>
            </div>
            <div class="abt_card meet_ins_1_main" >
                <div class="">
                <img src={trainer2} alt=""/>
                </div>
                <div class="abt_card_txt abt_card_txt_2">
                    <h5>K. Srimannarayana</h5>
                    <p>Horse Trainer</p>
                </div>
            </div>
        </div>
    </div>
  </section>

  <section className="coverflow_caro">
  <div class="our_shop_head all_head">
            <h6>OUR LOVELY HORSES</h6>
            <h1 style={{textTransform:'uppercase'}}>A bond beyond words, with our lovely horses.</h1>
        </div>

    <CoverflowCarousel/>
  </section>





      <section className='accordion_home'>
        <Accordion2/>
      </section>


            
            <div className="">
                <Contact/>
            </div>
            </div>:
            <div className='load_dis'>
                <div className="loader"></div>
            </div>}
        </div>
    );
};

export default Home;