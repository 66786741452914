import React from 'react';
import './Contact.css'
import { Link } from 'react-router-dom';
import logo_img from '../Images/Ruta_Logo_White.png'

const Contact = () => {
    return (
        <div>
            <section id="contact">
        <div class="">
            <div class="ctn_main">
                <div class="cnt_main_logo">
                    {/* <h1>RUTA EQUESTRIANS LLP</h1> */}
                    <img src={logo_img} alt="" className='logo_img' />

                </div>
                <ul class="cnt_ser">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/Activities">Activities</Link></li>
                    <li><Link to="/Services">Services</Link></li>
                    <li><Link to="/Gallery">Gallery</Link></li>
                    <li><Link to="/Contact">Contact</Link></li>
                </ul>
            </div>

            <div class="cnt_add">
                <div class="">
                    <p>Hyderabad —</p>
                    <p>Aziz nagar, Hyderabad <br/> -500075</p>
                    <p></p>
                </div>
                <div class="">
                    <h2><Link to={'tel:+91 954 230 0060'} style={{color:"#999"}}>+91 89 25 750 654 </Link></h2>
                    <p class="text-decoration-underline"><Link to={'mailto:rutaequestrians@gmail.com'} style={{color:'#999'}}>rutaequestrians@gmail.com</Link></p>
                </div>
                <div class="text-end">
                    <h3>Unspoiled Natural Beauty</h3>
                    <h3 style={{color:"#999"}}>True Luxury</h3>
                </div>
            </div>

            <div class="cnt_copy">
                <div class="">
                    <p> © 2024 All Rights Reserved.</p>
                </div>
                <div class="cnt_icons">
                    <Link to={'https://www.facebook.com/RutaEquestrians/'}><i class="fa-brands fa-facebook"></i></Link>
                    <Link to={''}><i class="fa-brands fa-x-twitter"></i></Link>
                    <Link to={''}><i class="fa-brands fa-dribbble"></i></Link>
                    <Link to={'https://www.instagram.com/rutaequestrians/'}><i class="fa-brands fa-instagram"></i></Link>
                </div>
            </div>

        </div>
      </section>

        </div>
    );
};

export default Contact;